<template>
  <div>
    <label
        class="flex items-center gap-[12px] text-[15px]/[16px] font-bold"
        :class="isChecked ? checkedClass : uncheckedClass"
    >
      <input
          type="checkbox"
          :value="value"
          :checked="isChecked"
          @change="toggleSelection"
          class="hidden w-[25px] h-[25px] rounded-small border border-charcoal cursor-pointer accent-vivid-purple"
          v-bind="fieldAttrs"
      >
      <span class="checkbox-accent relative w-[24px] h-[24px] rounded-small border-2 border-gray-300 cursor-pointer accent-vivid-purple">
        <IconCheckbox v-if="isChecked" class="w-[24px] h-[24px] absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2"/>
      </span>
      <span v-if="label">{{ label }}</span>
    </label>
  </div>
</template>



<script lang="ts">
import { defineComponent } from 'vue';
import IconCheckbox from "@components/icons/IconCheckbox.vue";

export default defineComponent({
  components: {IconCheckbox},
  props: {
    label: [String, null],
    modelValue: [Array, String, Number, Boolean],
    value: [String, Number, Boolean],
    fieldAttrs: { type: Object, default: () => ({}) },
    uncheckValue: null,
    checkedClass: {
      type: String,
      default: "text-black"
    },
    uncheckedClass: {
      type: String,
      default: "text-gray-900 dark:text-gray-300"
    }
  },
  emits: ["update:modelValue"],
  computed: {
    isChecked(): boolean {
      return Array.isArray(this.modelValue)
          ? this.modelValue.includes(this.value)
          : this.modelValue === this.value;
    }
  },
  methods: {
    toggleSelection() {
      let newValue;
      if (Array.isArray(this.modelValue)) {
        newValue = this.isChecked
            ? this.modelValue.filter((v: any) => v !== this.value)
            : [...this.modelValue, this.value];
      } else {
        newValue = this.isChecked ? this.uncheckValue : this.value;
      }
      // this.modelValue = newValue;
      this.$emit("update:modelValue", newValue);
    }
  },
});
</script>