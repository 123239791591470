<template>
  <div class="flex items-center gap-[10px] cursor-pointer" @click="toggleChecked">
    <div
      class="relative w-[24px] h-[24px] rounded-small border-2 border-gray-300 cursor-pointer accent-vivid-purple"
      :class="checked ? '' : ''"
    >
      <IconCheckbox v-if="checked" class="w-[24px] h-[24px] absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2" />
    </div>
      <span class="sm:ml-2 text-gray-700">{{ label }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import IconCheckbox from '@components/icons/IconCheckbox.vue'
export default defineComponent({
  components: { IconCheckbox },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const checked = ref(props.modelValue)

    const toggleChecked = () => {
      checked.value = !checked.value
      emit('update:modelValue', checked.value)
    }

    return {
      checked,
      toggleChecked
    }
  }
})
</script>
