import {defineStore} from 'pinia'
import {ref} from 'vue'
import {fetchDataWithError} from "@api/query";
import {useCookies} from "vue3-cookies";

export const useSignIn = defineStore('sign_in', () => {
    const contact = ref('')
    const { cookies } = useCookies();


    const getContact = () => {
        if(contact.value != ''){
            return contact.value
        }
        return cookies.get("code_contact")
    }

    const requestCodeMakeHandler = async (value: string, tokenRecaptcha: any) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

        if(!value){
            value = cookies.get("code_contact")
        }

        const isValidEmail = emailRegex.test(value);
        const isValidPhone = phoneRegex.test(value);

        if (!isValidEmail && !isValidPhone) {
            return {
                'success': false,
                'message': 'Invalid contact format'
            };
        }

        cookies.set("code_contact", value)

        const body = {
            type: isValidEmail ? 'email' : 'phone',
            typeText: isValidEmail ? 'email address' : 'phone number',
            value: value,
            'g-recaptcha-response': tokenRecaptcha
        };

        const response: any = await fetchDataWithError('auth/code/get', 'post', body);

        if (response.success) {
            contact.value = value;
        }

        return response;
    }



    return {
        getContact,
        contact,
        requestCodeMakeHandler,
    }
})
