<template>
  <h5 class="text-[19px]/[22px] sm:text-[27px]/[31px] font-semibold mt-[17px] mb-[13px]">
    Your information
  </h5>
  <div class="flex flex-col gap-[17px]">
    <div class="w-full flex flex-col justify-center relative">
      <CustomField
          :name="`customer_name`"
          :rules="[required]"
          :placeholder="`Full name`"
      />
      <span class="text-red-500 text-xs pt-1">{{ errors[`customer_name`] }}</span>
    </div>
    <div class="w-full flex flex-col justify-center relative">
      <CustomField
          :name="`customer_email`"
          :rules="[emailValidationWithEmpty]"
          :placeholder="`Email`"
      />
      <span class="text-red-500 text-xs pt-1">{{ errors[`customer_email`] }}</span>
    </div>
    <div class="w-full flex flex-col justify-center relative" v-if="emailEmptyShowChecker">
      <Field
          name="customer_empty_email"
          type="checkbox"
          :value="true"
          :unchecked-value="false"
          v-slot="{ field }"
      >
        <label class="flex items-center gap-[10px]">
          <input
              v-bind="field"
              type="checkbox"
              class="w-[25px] h-[25px] rounded-small border border-charcoal cursor-pointer accent-vivid-purple"
          />
          I don't have email
        </label>
      </Field>
      <span class="text-red-500 text-xs pt-1">{{ errors[`customer_empty_email`] }}</span>
    </div>
    <div class="w-full flex flex-col justify-center relative">
      <CustomField name="customer_phone" :rules="[required]" placeholder="Phone" />
      <span class="text-red-500 text-xs pt-1">{{ errors.phone }}</span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import CustomField from '@components/reusable/CustomField.vue'
import { required, emailValidationWithEmpty } from '@utills/helpers/validation'
import {Field} from "vee-validate";
export default defineComponent({
  components: {Field, CustomField },
  props: {
    emailEmptyShowChecker: {
      type: Boolean,
      required: true,
      default:false
    },
    errors: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      required,
      emailValidationWithEmpty
    }
  }
})
</script>