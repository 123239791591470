export function required(value: unknown): boolean | string {
  // return true;
  return value ? true : 'This field is required'
}
export function emailValidation(value: string) {
  const emailRegex = /\S+@\S+\.\S+/
  return emailRegex.test(value) ? true : 'Invalid email format'
}

export function emailValidationWithEmpty(value: string){
    const emailRegex = /\S+@\S+\.\S+/
  return value === '' || emailRegex.test(value) ? true : 'Invalid email format'
}
export const cvvValidation = (value: string) => {
  const regex = /^\d{3}$/
  return regex.test(value) ? true : 'Invalid cvv'
}
export function transformData(values: any, paymentInfo:any) {
  let customer = {
    name: values['customer_name'],
    phone: values['customer_phone'],
    signUp: values['signup'],
    email: null,
    has_email: false,
  }

  if(!values['customer_empty_email']){
    customer.email = values['customer_email']
    customer.has_email = true
  }

  const {
    hub_date: hubDate,
  } = values


  const subscriptions = []

  for (let i = 0; i <= 2; i++) {
    const nameDeliveryKey = `delivery_name-${i}`
    const planKey = `plan-${i}`
    const zipKey = `zip-${i}`
    const addressNameKey = `address_line_1-${i}`
    const addressLineKey = `address_line_2-${i}`
    const cityKey = `city-${i}`
    const stateKey = `state-${i}`
    const giftName = `recipients-${i}`
    const giftNote = `giftNote-${i}`
    const startMonth = `startMonth-${i}`
    if (values[planKey]) {
      subscriptions.push({
        cycle: values[planKey],
        startMonth: values[startMonth],
        gift: values[giftName] ? true : false,
        gift_information: {
          name: values[giftName],
          gift_note: values[giftNote]
        },
        hub_date: values[hubDate],
        address: {
          name: values[nameDeliveryKey],
          address_line_1: values[addressNameKey],
          address_line_2: values[addressLineKey],
          city: values[cityKey],
          state: values[stateKey],
          zip: values[zipKey]
        }
      })
    }
  }

  return {
    customer,
    paymentInfo,
    subscriptions
  }
}
