import type { IProduct, ITotal, IZip } from '@utills/types'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useSignUpCartsStore } from '@stores/sign-up-cart'


export const useTotalPaymentStore = defineStore('totalPayment', () => {
  const { addCart, removeCart, setCycle, calculateTax } = useSignUpCartsStore()

  const totalPayment = ref({
    zips: [],
    products: []
  } as ITotal)

  const handleZipsPayment = (selectedZip: IZip) => {
    totalPayment.value.zips.push(selectedZip)
  }

  const deleteZip = (id: number) => {
    if(!id){
      return;
    }
    setCycle(id)
    totalPayment.value.zips = totalPayment.value.zips.filter((it, idx) => idx !== id)
  }
  const handleAddAccessories = (product: any) => {
    const index = totalPayment.value.products.findIndex((p: IProduct) => p.id === product.id)
    if (index !== -1) {
      removeCart(product.id)
      totalPayment.value.products.splice(index, 1)
    } else {
      addCart(product)
      totalPayment.value.products.push(product)
    }
  }
  const calculateZip = () => {
    return totalPayment.value.zips.reduce((acc, zip) => acc + Number(zip.amount), 0)
  }
  const calculateProduct = () => {
    return totalPayment.value.products.reduce((acc, product) => acc + product.price, 0)
  }

  const calculateTotalAmount = () => {
    let total = 0

    // total += carts.reduce((acc, zip) => acc + Number(zip.amount), 0)
    // total += data.zips.reduce((acc, zip) => acc + Number(zip.amount), 0)

    // total += data.products.reduce((acc, product) => acc + product.price, 0)
    total += calculateTax()

    return total
  }
  return {
    totalPayment,
    handleZipsPayment,
    deleteZip,
    handleAddAccessories,
    calculateTotalAmount,
    calculateZip,
    calculateProduct,
  }
})
