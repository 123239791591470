<template>
  <CustomForm v-slot="{ errors }" @submit="onSubmit" class="w-full lg:w-1/2 relative z-[8]" ref="formSignUpRef">
    <div
        class="w-full pt-10 sm:pt-[46px] sm:pb-10 px-[23px] sm:pr-[54px] rounded-small bg-white lg:rounded-none -mt-1 lg:mt-0"
    >
      <h5 class="text-xl/[27px] sm:text-[22px]/[26px] font-bold sm:font-semibold">
        Sign up for a Vinderkind Subscription
      </h5>
      <div v-for="(item, index) in subscription" :key="index" class="">
        <SubscriptionSection
            :errors="errors"
            :addSubscription="addSubscription"
            :subscription="item"
            :index="index"
            :emailEmptyShowChecker="emailEmptyShowChecker"
            :allSubscription="subscription"
            @enableSection="enableSection"
        />
      </div>
      <div :class="isEnabled ? 'opacity-100' : 'opacity-50 pointer-events-none'">
        <AccessoriesInformation/>
        <PaymentInformation
            :errors="errors"
            page="signup"
        />
        <button
          class="mt-[15px] mb-[18px] bg-vivid-purple w-full py-4 text-[21px]/[25px] text-white rounded-small font-bold hover:opacity-70 transition-all ease-in duration-150"
          :disabled='spinning'
        >
          <div
          v-if="spinning"
          class="px-[15px] cursor-pointer flex items-center bg-vivid-purple rounded-small justify-center w-full text-white gap-[13px] sm:gap-5 hover:opacity-70 transition-all duration-200 ease-linear [&_.loader]:!min-h-5 [&_.loader]:!h-5 [&_.item]:!w-6 [&_.item]:!h-6 [&_.item]:!border-2"
          >
            <CustomLoader/>
          </div>
          <div v-else>
            <template v-if="cartResponse.isFullyDiscounted">Confirm</template>
            <template v-else>Subscribe & Pay ${{ Number(calculateTotal()).toFixed(2) }}</template>
          </div>
        </button>
        <div class="text-[13px]/[17px] flex flex-col">
          <span class="text-center">By clicking on the subscribe button, you agree with our </span>
          <button @click.prevent="openModal" class="text-center text-vivid-purple">
            terms and conditions & cancellation policy.
          </button>
        </div>
      </div>
    </div>
  </CustomForm>
  <SharedModal v-model="open">
    <TermsConditions @close="closeModal"/>
  </SharedModal>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, watch, useTemplateRef, type Ref} from 'vue'
import {Form as CustomForm} from 'vee-validate'
import PaymentInformation from '@components/payments/PaymentInformation.vue'
import AccessoriesInformation from '@components/sign-up/AccessoriesInformation.vue'
import SubscriptionSection from '@components/sign-up/SubscriptionSection.vue'
import SharedModal from '@components/reusable/SharedModal.vue'
import TermsConditions from '@components/sign-up/TermsConditions.vue'
import CustomLoader from '@components/reusable/CustomLoader.vue'
import {usePlansStore} from '@stores/plans'
import {useOrderStore} from '@stores/sign-up'
import {useTotalPaymentStore} from '@stores/totalPayment'
import {useSignUpCartsStore} from '@stores/sign-up-cart'
import {transformData} from '@utills/helpers/validation'
import {useReCaptcha} from 'vue-recaptcha-v3'
import {usePayment} from "@stores/payment";

export default defineComponent({
  components: {
    PaymentInformation,
    AccessoriesInformation: AccessoriesInformation,
    CustomForm,
    SubscriptionSection,
    SharedModal,
    TermsConditions,
    CustomLoader
  },
  data() {
    return {
      isEnabled: false
    }
  },
  methods: {
    enableSection(value: boolean) {
      this.isEnabled = value
    }
  },

  setup() {
    const reCaptcha = useReCaptcha();
    const formSignUpRef = useTemplateRef('formSignUpRef') as Ref<HTMLFormElement | null>;
    const {getPaymentData} = usePayment()
    const {calculateTotalAmount, totalPayment} = useTotalPaymentStore()
    const {calculateTotal, cartResponse} = useSignUpCartsStore()
    const signUp = useOrderStore()
    const {publication} = usePlansStore()
    const couponCode = ref('')
    const emailEmptyShowChecker = ref(false)

    const open = ref(false)
    let subscription = ref([{zipCode: ''}])
    let spinning = ref(false)
    const openModal = () => {
      open.value = true
    }
    const closeModal = () => {
      open.value = false
    }

    function addSubscription() {
      subscription.value.push({zipCode: ''})
    }

    watch(cartResponse, () => {
      couponCode.value = cartResponse.couponCode;
    })

    async function onSubmit(values: any) {
      spinning.value = true

      if(!values['customer_email'] && !values['customer_empty_email']){
        spinning.value = false;
        emailEmptyShowChecker.value = true;

        if(formSignUpRef.value) {
          formSignUpRef.value.setErrors({
            'customer_email': 'This field is required'
          })
        }

        const input = document.querySelector(`[name="customer_email"]`) as HTMLInputElement | null;
        if (input) {
          input.scrollIntoView({ behavior: "smooth", block: "center" });
          setTimeout(() => input.focus(), 500);
        }
        return;
      }

      const tokenRecaptcha = async () => {
        await reCaptcha?.recaptchaLoaded();
        return reCaptcha?.executeRecaptcha('login');
      }

      const cloneProducts = totalPayment.products
      const products = cloneProducts.map((item) => {
        return {
          id: item.id,
          quantity: 1
        }
      })

      if(cartResponse.isFullyDiscounted){
        await signUp.submitOrder({
          ...transformData(values, []),
          products
        }, '/subscription-confirmation', await tokenRecaptcha())
        spinning.value = false;
        return;
      }

      const paymentInfo = await getPaymentData()

      if (paymentInfo === 'error') {
        spinning.value = false


        return;
      }

      await signUp.submitOrder({
        ...transformData(values, paymentInfo),
        products
      }, '/subscription-confirmation', await tokenRecaptcha())
      spinning.value = false;
    }

    return {
      subscription,
      publication,
      onSubmit,
      addSubscription,
      open,
      openModal,
      closeModal,
      calculateTotalAmount,
      calculateTotal,
      totalPayment,
      spinning,
      couponCode,
      emailEmptyShowChecker,
      formSignUpRef,
      cartResponse
    }
  }
})
</script>
