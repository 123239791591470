import { defineStore } from 'pinia'
import { fetchData } from '@api/query'
import router from '@router/index'
import { useToast } from 'vue-toast-notification'
import { useThankYouStore } from '@stores/thankyou'
import 'vue-toast-notification/dist/theme-sugar.css'
import { useCartsStore } from '@stores/carts'
import {usePayment} from "@stores/payment";

const $toast = useToast()
interface GiftInformation {
  name: string
  gift_note: string
}

interface Address {
  name: string
  address_line_1: string
  address_line_2: string
  city: string
  state: string
  zip: string
}

interface Subscription {
  cycle: 'yearly' | 'monthly'
  startMonth: number
  gift: boolean
  gift_information?: GiftInformation
  address: Address
}

interface OrderState {
  subscriptions: Subscription[],
  covers: { image: string;}[]
}

export const useOrderStore = defineStore('order', {
  state: (): OrderState => ({
    subscriptions: [],
    covers: []
  }),
  actions: {
    async submitOrder(values: Object, redirect:any, tokenRecaptcha:any) {
      const { setOrder } = useThankYouStore()
      const {clearPaymentData} = usePayment()

      const response: any = await fetchData('orders', 'post', {
        order: values,
        'g-recaptcha-response': tokenRecaptcha
      })

      if (!response.err) {
        clearPaymentData();
        setOrder(response.data)

        if(redirect == '/order-confirmation') {
          const { clearCart } = useCartsStore()
          clearCart()
        }

        await router.push(redirect)

        $toast.open({
          message: 'Success!',
          type: 'success',
          position: 'top-right'
        })
      } else {
        $toast.open({
          message: response.message,
          type: 'error',
          position: 'top-right'
        })
      }
    },
    async fetchCovers() {
      try {
        const response: any = await fetchData('getCoverImages', 'get')
        this.covers = response?.data // Use 'this.covers' to update state
      } catch (error) {
        console.error('Error fetching covers:', error)
      }
    }
  },
})
