<template>
  <div
    class="m-auto max-w-[478px] w-full pt-[31px] sm:pt-[67px] pb-[128px] flex flex-col items-center px-5 lg:px-0"
    v-if="isData"
  >
    <h4 class="text-[26px] sm:ext-3xl/[35px] font-bold font-raleway mb-[17px] text-center">
      Thank you for your Order!
    </h4>
    <span class="text-[15px]/[18px] text-charcoal font-raleway text-center"
          v-if="data.email"
      >We’ve also emailed you a confirmation to {{ data.email }}</span
    >
    <OrderDetail :data="data"/>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import OrderDetail from '@components/order-confirmation/OrderDetail.vue'
import { useThankYouStore } from '@stores/thankyou'
import router from "@/router";

export default defineComponent({
  setup() {
    const { getOrder } = useThankYouStore()
    
    const data = ref()
    const isData = ref<boolean>(false)

    onMounted(async () => {
      const response = getOrder();
      isData.value = !!Object.keys(response).length;
      if(!isData.value) {
        await router.push('/')
      }else{
        data.value = response;
      }
    })
    return {
      data,
      isData
    }
  },
  components: { OrderDetail }
})
</script>
