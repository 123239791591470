import {defineStore, type StoreDefinition} from 'pinia'
import {reactive, ref} from 'vue'
import {fetchData} from '@api/query';
import type {ICartItem} from '@utills/types'


export const useSignUpCartsStore: StoreDefinition<string> = defineStore('sign-up-carts', () => {
    const cycle = ref({})
    const carts = ref<ICartItem[]>([])
    const tax = ref(0)
    const shipping = ref(0)
    const discount = ref(0)
    const total = ref(0)
    const spinnings = ref<number[]>([])
    const hebSelect = ref([]);
    const postcode = ref(null)
    const cartResponse = reactive({
        couponCode: '',
        isFullyDiscounted: false
    });
    const paymentMethod = import.meta.env.VITE_PAYMENTS_TYPE

    const fetchHebDates = async () => {
        try {
            const response:any = await fetchData('signup/getHebSelect', 'get');
            hebSelect.value = response?.data;
        } catch (error) {
            console.error('Error fetching heb date:', error);
        }
    }

    const fetchCarts = async () => {
        try {
            const response: any = await fetchData('signup', 'get');
            response?.data.products.forEach((product: ICartItem) => {
                const cart = carts.value.find((cart: ICartItem) => cart.id === product.id)
                if (cart) {
                    cart.count = product.count
                } else {
                    carts.value.push(product)
                }
            })

            //todo, maybe we need to do it, maybe not
            // when user refreshs the page we start with clean slate
            // when user clicks on remove, we remove it manually
            // ?? remove stuff not in products

            tax.value = response.data.tax

            total.value = response.data.total

            cycle.value = response.data.cycle?.price ?? 0

            shipping.value = response.data.shipping

            discount.value = response.data.discountTotal

            cartResponse.couponCode = response.data.coupon_code ?? ''

            cartResponse.isFullyDiscounted = response.data.isFullyDiscounted ?? false

            postcode.value = response.data.postcode ?? null

            clearSpinnings()
        } catch (error) {
            console.error('Error fetching accessories:', error);
        }
    };

    fetchCarts()

    const setCycle = async (cycleId: number) => {
        await fetchData('signup/setCycle', 'post', {
            cycle_id: cycleId,
        });
        fetchCarts()
    }

    const setDiscount = async (discount: any) => {
        const response: any = await fetchData('signup/setDiscount', 'post', {
            discount,
        });
        fetchCarts()
        return response
    }

    const setShipment = async (postcode: any) => {
        const response:any = await fetchData('signup/setShipping', 'post', {
            postcode,
        });
        fetchCarts()
        return response
    }


    const unsetDiscount = async () => {
        await fetchData('signup/unsetDiscount', 'post');
        fetchCarts()
    }

    const updateCount = async (cartId: number, increment = true) => {
        addtoSpinning(cartId)
        await fetchData('signup/incrementItem', 'post', {
            product_id: cartId,
            increment
        });
        const cart = carts.value.find((cart) => cart.id === cartId)
        if (cart) {
            if (increment) {
                cart.count++
            } else {
                if (cart.count > 1) {
                    cart.count--
                } else {
                    removeCart(cartId)
                    cart.count = 0
                }
            }
        }
        fetchCarts()
    }

    const addCart = (product: any, increment = true) => {
        const existingCart = carts.value.find((c) => c.id === product.id)
        if (increment) {
            if (existingCart) {
                updateCount(product.id)
            } else {
                carts.value.push(product)
                updateCount(product.id)
            }
        } else {
            carts.value.forEach((cart) => {
                updateCount(cart.id, false);
            });
        }
        // fetchCarts()
    }

    const removeCart = async (cartId: number) => {
        addtoSpinning(cartId)
        await fetchData('signup/remove', 'post', {
            product_id: cartId,
        });
        const index = carts.value.findIndex((cart) => cart.id === cartId)
        if (index !== -1) {
            carts.value.splice(index, 1)
        }
        fetchCarts()
    }

    const getCount = (cart: ICartItem) => {
        const existingCart = carts.value.find((c) => c.id === cart.id)
        if (existingCart) {
            return existingCart.count;
        } else {
            return 0;
        }
    }

    const addtoSpinning = (cartId: number) => {
        spinnings.value.push(cartId)
    }

    const getSpinning = (cartId: number) => {
        return spinnings.value.find((c) => c == cartId)
    }

    const clearSpinnings = () => {
        spinnings.value.splice(0, spinnings.value.length)
    }

    const clearCart = async () => {
        await fetchData('signup/clear', 'post', {});
        carts.value.splice(0, carts.value.length)
        fetchCarts()
    }

    const calculateTotal = () => {
        return Number(total.value);
    }

    const calculateDiscount = () => {
        return Number(discount.value);
    }


    const calculateCycle = () => {
        return Number(cycle.value)
    }

    const calculateProducts = () => {
        let total = 0
        carts.value.forEach((cart) => {
            total += cart.count * cart.price
        })
        return Number(total)
    }

    const calculateTax = () => {
        return Number(tax.value)
    }

    const calculateShipping = () => {
        return Number(shipping.value)
    }

    const getCarts = () => {

        return carts.value
    }

    return {
        cycle,
        carts,
        tax,
        discount,
        shipping,
        updateCount,
        removeCart,
        calculateTotal,
        calculateShipping,
        calculateTax,
        calculateCycle,
        calculateDiscount,
        setCycle,
        addCart,
        clearCart,
        getCount,
        getSpinning,
        fetchCarts,
        spinnings,
        getCarts,
        setDiscount,
        unsetDiscount,
        setShipment,
        fetchHebDates,
        hebSelect,
        cartResponse,
        paymentMethod
    }
})
