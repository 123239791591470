import axios from 'axios'
import {useToast} from 'vue-toast-notification';

const API_URL = import.meta.env.VITE_API_URL
axios.defaults.headers.common['Accept'] = 'application/json';

export const fetchData = async (path: string, method = 'get', body: object | null = null) => {
  const url = `${API_URL}${path}`

  try {
    let response
    if (method.toLowerCase() === 'get') {
      response = await axios.get(url)
    } else if (method.toLowerCase() === 'post') {
      response = await axios.post(url, body)
    } else if (method.toLowerCase() === 'put') {
      response = await axios.put(url, body);
    }
    return response
  } catch (error:any) {
    let message = 'Something went wrong!';
    if(error?.response?.data?.message && error.status === 422){
      message = error.response.data.message;
    }

    return {
      err: 'Request failed',
      message: message
    }
  }
}
export const fetchDataWithError = async (path: string, method = 'get', body: object | null = null) => {
  const url = `${API_URL}${path}`
  let response: any;


  const $toast = useToast();

  try {
    if (method.toLowerCase() === 'get') {
      response = await axios.get(url);
    } else if (method.toLowerCase() === 'post') {
      response = await axios.post(url, body);
    } else if (method.toLowerCase() === 'put') {
      response = await axios.put(url, body);
    }

    if(response.data.message){
      $toast.success(response.data.message);
    }

    return {
      'success': true,
      'data': response.data
    };
  } catch (error: any) {
    if(error?.response?.status === 401){
      $toast.error('Redirect to page authorization...');
      window.location.reload();
    }else {
      $toast.error(error?.response?.data?.message ?? error?.message);
    }
    return {
      'success': false,
      'message': error?.response?.data?.message ?? error?.message,
      'data': error?.response?.data
    };
  }
};