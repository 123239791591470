<template>
  <div class="w-[273px] max-md:w-full shrink-0 ">
    <div class="rounded-small border border-vivid-purple px-[15px]" dir="rtl">
      <button
          type="button"
          v-on:click="toggle"
          class="text-black font-hebrew text-[29px]/[20px] flex justify-between w-full items-center py-4 gap-[12px]"
          :class="{
            ' border-b-[1px] border-vivid-purple': status
          }"
      >
        <span class="flex-1 text-right font-bold">{{ title }}</span>
        <IconDropdown :class="{'rotate-180': !status}" class="w-[16px] h-[16px] fill-black"/>
      </button>

      <div v-if="status" class="flex-column my-[20px] gap-[16px] filter-height">
        <template v-for="(item) in filters.years" :key="key">
          <Checkbox :label="item" v-model="selected" :value="item" class="mb-4"/>
        </template>
      </div>
    </div>
  </div>
</template>


<style>
.filter-height{
  max-height: 804px;
  overflow: auto;
}
</style>

<script lang="ts">
import {ref, defineComponent, watch} from "vue";
import type {IMonth} from "@utills/types";
import IconFilter from "@components/icons/IconFilter.vue";
import IconDropdown from "@components/icons/IconDropdown.vue";
import { FwbCheckbox } from 'flowbite-vue'
import Checkbox from "@components/reusable/Checkbox.vue";


export default defineComponent({
  components: {Checkbox, FwbCheckbox,  IconDropdown, IconFilter},
  props: {
    filters: {
      type: Object,
      default: {
        years: []
      }
    },
    filterHandler: {
      type: Function,
      default: () => {}
    }
  },
  data(){
    return {
      title: 'יאר',
      status: false
    }
  },
  methods: {
    toggle(){
      this.status = !this.status
    }
  },
  setup(props){
    const selected = ref<any>([]);

    watch(selected, (current:any) => {
      props.filterHandler(current);
    });

    return {
      selected
    }
  }
})
</script>