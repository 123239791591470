<template>
  <div>
    <ContentWithPaginate
        :page="page"
        :data="allIssues"
        :filter="true"
        :preview="true"
        :loading="loading"
    >
      <template v-slot:filter>
        <ContentHebDateFilter :filters="filter" :filterHandler="filterHandler"></ContentHebDateFilter>
      </template>
    </ContentWithPaginate>
  </div>
</template>
<script lang="ts">
import ContentWithPaginate from '@components/reusable/ContentWithPaginate.vue'
import { useIssuesStore } from '@stores/issues'
import { defineComponent, onMounted, ref } from 'vue'
import ContentHebDateFilter from "@components/reusable/ContentHebDateFilter.vue";
import router from "@/router";
export default defineComponent({
  components: {ContentHebDateFilter, ContentWithPaginate },

  setup() {
    const allIssuesStore = useIssuesStore()
    const loading = ref(true)

    const allIssues = ref([])
    const filter = ref({years: []})

    const filterHandler = async (years:any) => {
      loading.value = true
      await allIssuesStore.fetchIssues({
        years: years
      })
      allIssues.value = allIssuesStore.allIssues
      loading.value = false
    }

    onMounted(async () => {
      await allIssuesStore.fetchIssues()
      await allIssuesStore.fetchFilterIssues()
      allIssues.value = allIssuesStore.allIssues
      filter.value = allIssuesStore.filterIssues
      loading.value = false
    })

    return {
      filterHandler,
      allIssues,
      filter,
      loading
    }
  },

  data() {
    return {
      page: {
        title: 'טעקעס',
        enTitle: 'Issues'
      }
    }
  }
})
</script>
