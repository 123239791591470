<template>
  <div
      class="flex flex-col max-w-[524px] m-auto w-full pt-[31px] sm:pt-[66px] pb-[66px] px-5 sm:px-0 lg:pt-[167px]"
  >
    <h5 class="text-[27px] sm:text-3xl/[35px] font-bold font-raleway mb-[19px]">
      Sign in with a One-time code.
    </h5>
    <form @submit="onSubmit" class="w-full">
      <span class="text-lg/[21px] text-charcoal font-raleway pb-3">
        Enter your email address or phone number
      </span>
      <Field
          data-vv-as="contact information"
          name="contact"
          v-model="contact"
          class="border-charcoal border rounded-small placeholder:text-silver text-xl h-[53px] px-[18px] w-full mt-[13px]"
          placeholder="Email address or phone number"
      />

      <span class="text-xs text-red-500">{{ errors.contact }}</span>

      <button
          :disabled="spinning"
          class="mt-[18px] bg-vivid-purple w-full py-4 text-[21px]/[25px] text-white rounded-small font-bold hover:opacity-70 transition-all ease-in duration-150"
      >
        <span
            v-if="spinning"
            class="px-[15px] cursor-pointer flex items-center bg-vivid-purple rounded-small justify-center w-full text-white gap-[13px] sm:gap-5 hover:opacity-70 transition-all duration-200 ease-linear [&_.loader]:!min-h-5 [&_.loader]:!h-5 [&_.item]:!w-6 [&_.item]:!h-6 [&_.item]:!border-2"
        >
          <CustomLoader/>
        </span>
        <span v-else>
        Get code
        </span>
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue'
import {Field, Form as CustomForm, useForm} from 'vee-validate'
import * as yup from 'yup';
import {useSignIn} from '@stores/sign-in'
import router from "@/router";
import CustomLoader from "@components/reusable/CustomLoader.vue";
import {useReCaptcha} from "vue-recaptcha-v3";

export default defineComponent({
  components: {CustomLoader, Field, CustomForm},
  setup() {
    const signIn = useSignIn();
    const reCaptcha = useReCaptcha();
    const spinning = ref<boolean>(false)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    const contact = ref('')
    const {errors, handleSubmit} = useForm({
      validationSchema: yup.object({
        contact: yup
            .string()
            .test('is-valid-email-or-phone', 'Invalid email or phone number', (value: any) => {
              return emailRegex.test(value) || phoneRegex.test(value);
            })
            .required('Contact is required'),
      })
    })

    const onSubmit = handleSubmit(async (values, actions) => {
      const tokenRecaptcha = async () => {
        await reCaptcha?.recaptchaLoaded();
        return reCaptcha?.executeRecaptcha('login');
      }

      spinning.value = true;
      const response = await signIn.requestCodeMakeHandler(values.contact, await tokenRecaptcha())
      if (response.success) {
        router.push({name: 'GetCode'});
      } else {
        actions.setErrors({'contact': response.message})
      }
      spinning.value = false;
    });

    return {
      spinning,
      errors,
      onSubmit,
      contact
    }
  }
})
</script>
