<template>
  <div v-if="carts.length">
    <div v-if="spinnings.length">
      <CustomLoader />
    </div>
    <div v-else>
      <div class="flex flex-col gap-[21px] items-center w-full">
        <div v-for="(cart, index) in carts" :key="index" class="w-full flex justify-center">
          <CartItem :cart="cart" :allData="carts" />
        </div>
      </div>
  <div
  class="flex flex-col justify-between bg-lavender rounded-small py-[14px] px-[22px] mt-6">
    <div class="flex justify-between">
      <span class="text-base sm:text-xl/6">
        <template v-if="postcode">Total Payment ${{ Number(calculateTotal()).toFixed(2) }}</template>
        <template v-else>Estimated Payment ${{ Number(calculateTotal()).toFixed(2) }}</template>
      </span>
      <button @click.prevent="toggleViewMore" class="text-[15px] text-vivid-purple font-raleway">
        {{ !viewMore ? 'View Details' : 'Hide Details' }}
      </button>
    </div>
    <div v-if="viewMore" class="mt-4">
      <div class="flex flex-col gap-3">
        <div v-if="getCarts().length">
          <div v-for="(item, index) in getCarts()" :key="index">
            <div class="flex justify-between text-base items-center">
              <span>{{ item.name }}</span>
              <span>${{ item.price }}</span>
            </div>
          </div>
        </div>

        <div v-if="calculateShipping()">
            <div class="flex justify-between text-base items-center">
              <span>Shipping</span>
              <span>${{ Number(calculateShipping()).toFixed(2) }}</span>
            </div>
        </div>
        <div v-if="!calculateShipping()">
          <div class="flex justify-between text-base items-center">
            <span>Shipping</span>
            <span>Free</span>
          </div>
        </div>

        <div v-if="!calculateShipping()">
          <div class="sub_text">
            <small>We prelminary calculate shipping assuming you are placing an order from USA and it will recalculated more accurately on Checkout further.</small>
          </div>
        </div>

        <div v-if="calculateTax()">
            <div class="flex justify-between text-base items-center">
              <span>Tax</span>
              <span>${{ Number(calculateTax()).toFixed(2) }}</span>
            </div>
        </div>

        <div v-if="calculateDiscount()">
            <div class="flex justify-between text-base items-center">
              <span>Discount</span>
              <span>-${{ Number(calculateDiscount()).toFixed(2) }}</span>
            </div>
        </div>
      </div>
    </div>
  </div>
      <div v-if="false" class="bg-lavender px-[22px] py-[18px] rounded-small flex justify-between my-4">
        <div class="flex flex-col gap-[10px]">
          <div v-if="false">
            <span class="text-base/5">Shipping to 11219</span>
            <button class="text-vivid-purple text-[13px] pl-[9.5px]" @click="openModal">
              Change
            </button>
          </div>
          <span class="text-base/5">Sales Tax</span>
        </div>
        <div class="flex flex-col [&_span]:text-base gap-[10px]">
          <span>${{ Number(calculateTax()).toFixed(2) }}</span>
        </div>
      </div>
      <div
      class="px-[22px] py-[18px] "
      ></div>
      <router-link
        to="/checkout"
        class="flex justify-center bg-vivid-purple w-full py-4 text-[21px]/[25px] text-white rounded-small font-bold hover:opacity-70 transition-all ease-in duration-150"
      >
        <template v-if="postcode">Checkout  ${{ Number(calculateTotal()).toFixed(2) }}</template>
        <template v-else>Continue to Checkout </template>
      </router-link>
    </div>
  </div>
  <div v-else>
    <p class="text-xl font-bold text-center">Your cart is empty.</p>
  </div>
  <SharedModal v-model="open">
    <UpdateZipModal @close="closeModal" />
  </SharedModal>
</template>

<script lang="ts">
import CartItem from '@components/reusable/CartItem.vue'
import SharedModal from '@components/reusable/SharedModal.vue'
import UpdateZipModal from '@components/user-information/UpdateZipModal.vue'
import { defineComponent, ref} from 'vue'
import { useCartsStore } from '@stores/carts'
import CustomLoader from '@components/reusable/CustomLoader.vue'
export default defineComponent({
  components: { CartItem, SharedModal, UpdateZipModal, CustomLoader },

  data() {
    return {
      paymentInfo: [
        {
          placeholder: 'Expiration Date',
          field: 'expDate',
          valid: this.required,
          mask: '##/##'
        },
        {
          placeholder: 'CVV',
          field: 'cvv',
          valid: this.required,
          mask: '####'
        },
        {
          placeholder: 'Billing ZIP Code',
          field: 'billing',
          valid: this.required,
          mask: '#####'
        }
      ],
      yourInfo: [
        {
          placeholder: 'Full name',
          field: 'name',
          valid: this.required
        },
        {
          placeholder: 'Email',
          field: 'email',
          valid: this.emailValidation
        }
      ],
      deliveryInfo: [
        {
          placeholder: 'Name',
          field: 'name',
          valid: this.required
        },
        {
          placeholder: 'Address',
          field: 'address',
          valid: this.required
        },
        {
          placeholder: 'Address Line 2',
          field: 'address2'
        },
        {
          placeholder: 'City',
          field: 'city',
          valid: this.required
        },
        {
          placeholder: 'State',
          field: 'state',
          valid: this.required
        },
        {
          placeholder: 'Zip Code',
          field: 'zip',
          valid: this.required
        }
      ]
    }
  },
  setup() {

    const { carts, spinnings, postcode, calculateDiscount, calculateShipping, calculateTax, calculateTotal, getCarts } = useCartsStore()
    //const {  carts, shipping, calculateTax,  } = useCartsStore()

    const open = ref(false)
    const viewMore = ref(false)
    const openModal = () => {
      open.value = true
    }
    const closeModal = () => {
      open.value = false
    }
    function toggleViewMore() {
      viewMore.value = !viewMore.value
    }

    return {
      carts,
      open,
      postcode,
      openModal,
      closeModal,
      spinnings,
      viewMore,
      toggleViewMore,
      calculateDiscount, calculateShipping, calculateTax, calculateTotal, getCarts
    }
  }
})
</script>
