import { defineStore } from 'pinia'
import { ref } from 'vue';
import { fetchData } from '@api/query';

export const useIssuesStore = defineStore('issues', () => {
  const lastIssues = ref([]);
  const allIssues = ref([]);
  const filterIssues = ref({years: []});

  const fetchIssues = async (filter:object = {}) => {
    try {
      const allIssuesResponse:any = await fetchData('issues', 'post', filter);
      allIssues.value = allIssuesResponse?.data.data;
    } catch (error) {
      console.error('Error fetching issues:', error);
    }
  };

  const fetchLastIssues = async () => {
    try {
      const response:any = await fetchData('issues/latest', 'get');
      lastIssues.value = response?.data; 
    } catch (error) {
      console.error('Error fetching last Issues:', error);
    }
  };

  const fetchFilterIssues = async () => {
    try {
      const response:any = await fetchData('filters', 'get');
      filterIssues.value = response?.data;
    }catch (error) {
      console.error('Error fetching filter Issues:', error);
    }
  };

  return { allIssues, lastIssues, filterIssues, fetchIssues, fetchLastIssues, fetchFilterIssues };
});
