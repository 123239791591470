<template>
  <div class="border-b border-light-gray pb-[27px]">
    <h5 class="text-[19px]/[22px] sm:text-[27px]/[31px] font-semibold mt-[17px] mb-[13px]">
      Accessories
    </h5>
    <div v-if="lastAccessories?.length" >
      <div class="flex flex-col gap-3 " :style="{ maxHeight: 'calc(500px + 1.5rem)' }"
        :class="{'overflow-y-auto' : showMore}"
      >
        <template v-for="(cart, index) in lastAccessories" :key="index">
          <div class="w-full flex justify-center" v-if="index < 3 || showMore">
          <div class="rounded-small border border-charcoal py-4 px-4 sm:px-6 flex gap-5 w-full">
            <div @click="handleAddAccessories(cart)" class="h-max">
              <SharedCheckBox v-model="checked" />
            </div>
            <div class="w-[132px] h-[132px]">
              <img :src="cart.img" alt="accessories" class="w-full h-full object-contain" />
            </div>
            <div class="flex flex-col gap-[7px] mt-2">
              <span class="text-vivid-purple text-[19px]/[22px] font-raleway font-semibold">
                {{ cart.name }}
              </span>
              <span class="text-[21px] font-bold font-sans">${{ Number(cart.price).toFixed(2) }}</span>
            </div>
          </div>
        </div>
        </template>
      </div>
      <div v-if="!showMore" class="flex justify-center mt-[13px]">
        <button type="button" @click="showMoreOpen" class="font-raleway flex gap-3 items-center px-[17px] cursor-pointer py-2 w-max bg-lavender rounded-[18px] text-vivid-purple relative">
          Show more
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import SharedCheckBox from '@components/reusable/SharedCheckBox.vue'
import { useAccessoriesStore } from '@stores/accessories'
import { useTotalPaymentStore } from '@stores/totalPayment'
import Checkbox from "@components/reusable/Checkbox.vue";
export default defineComponent({
  components: {Checkbox, SharedCheckBox },
  setup() {
    const { totalPayment, handleAddAccessories } = useTotalPaymentStore()
    let checked = ref(false)
    const lastAccessoriesStore = useAccessoriesStore()
    const showMore = ref(false)

    const showMoreOpen = () => {
      showMore.value = true
    }

    const lastAccessories = ref([
      {
        img: '',
        name: '',
        price: ''
      } as any
    ])

    onMounted(async () => {
      await lastAccessoriesStore.fetchSignupAccessories()
      lastAccessories.value = lastAccessoriesStore.signupAccessories
    })

    return {
      showMore,
      showMoreOpen,
      checked,
      lastAccessories,
      totalPayment,
      handleAddAccessories
    }
  }
})
</script>
